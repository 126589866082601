<template>
  <div class="mt-5 text-right">
    <v-btn
      class="ml-0 mr-3 cancel-button"
      :disabled="getCancelDisable"
      v-on:click="$emit('goBack')"
      >{{ $t("Cancel") }}</v-btn
    >
    <v-btn
      color="primary save-button"
      v-on:click="confirmFunction"
      :loading="loading"
      >{{ confirmLabel }}</v-btn
    >
  </div>
</template>

<script>
export default {
  props:{
    isNonRepeatingPage: {
      type: Boolean,
      default: false,
    },
    confirmFunction: {
    },
    confirmLabel: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
  } ,
  data() {
    return {
      cancelDisable: this.isNonRepeatingPage,
    };
  },
  computed:{
    getCancelDisable:{
      get(){
        return this.cancelDisable
      },
      set(newItem){
        this.cancelDisable=newItem
      }
    }
  },
  methods: {
    cancelDisableToggle() {
      this.cancelDisable = false;
    },
  },
};
</script>

<style>
</style>
